import { apiPost } from '~src/api/client'
import { IFacetChoices, IOccasionResponse, SortType } from '~src/api/types/occasion'

export const postOccasions = (
  occasionApiUrl: string,
  facets: IFacetChoices,
  sort: SortType,
  page: number = 0,
  dealer: number,
  limit = 12,
  start = page * limit
): Promise<IOccasionResponse> =>
  apiPost<IOccasionResponse>(
    occasionApiUrl,
    {
      facets: {
        ...facets,
        ...(facets.Location?.zipCode && { Location: { values: [facets.Location.zipCode] } }),
        ...(facets.Location?.distance && { Distance: { values: [facets.Location.distance] } }),
        ...(facets.Location?.lat && { Lat: { values: [facets.Location.lat] } }),
        ...(facets.Location?.lng && { Lng: { values: [facets.Location.lng] } }),
        ...(facets.Year && { Year: nonNullableRange(facets.Year.range) }),
        ...(facets.Mileage && { Mileage: nonNullableRange(facets.Mileage.range) }),
        ...(facets.PriceOnline && { PriceOnline: nonNullableRange(facets.PriceOnline.range) }),
        ...(dealer
          ? { ClientId: { values: [dealer.toString()] } }
          : { Brand: { values: ['Suzuki'] } }),
      },
      limit,
      sort,
      start,
    },
    { withCredentials: false }
  ).then((r) => r.data)

// Due to changes in the API, we need to make sure that min/max values can always be parsed as integers.
// For this, we need to define absolute min/max values as fallbacks.
// https://auto.nl/documentation/changelogs/cardataservice/
const nonNullableRange = ([min, max]: string[]) => {
  const minValue = min !== '' ? min : '0'
  const maxValue = max !== '' ? max : '1000000'
  return { range: [minValue, maxValue] }
}