/***
 * @name Google Map Helper
 *
 * @description Abstracts a lot of the functionality away from the React component
 */

// == Local imports
import { IDealerInfo } from '~src/apps/DealerMap/types'
import TOUCH_SUPPORTED from '~src/utils/touch'

//
// = Markers
const MARKER: string =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNyA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC45MTY0IDIwLjc4MjhDMjYuMjM2MSAxOC42ODk3IDI3IDE2LjIwODYgMjcgMTMuNTQ4NEMyNyA2LjA2NTgyIDIwLjk1NTggMCAxMy41IDBDNi4wNDQxNiAwIDAgNi4wNjU4MiAwIDEzLjU0ODRDMCAxNi4yMDg2IDAuNzYzOTI5IDE4LjY4OTcgMi4wODM2MyAyMC43ODI4TDEzLjUgNDJMMjQuOTE2NCAyMC43ODI4WiIgZmlsbD0iI0RFMDAzOSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjM3NjEgMjFDMTQuNDQxMSAxOS44MjYxIDE5Ljc0MTggMTYuNTkyNSAyMS4wNDExIDE2LjQ1QzIwLjQzODYgMTUuOTc4MiAxMS42OTExIDkuOTcxNzkgMTEuNTUgOS44OTU3MUMxMS4yNzM5IDkuNzQ2NDMgMTEuNjcxOCA5LjMyMzkzIDEyLjAwNjQgOS41OTQ2NEMxMi4yMzMyIDkuNzc3ODYgMTUuMDMzNiAxMS42MTA3IDE1LjM4NSAxMS44NTkzQzE3LjE2ODkgMTMuMTE4OSAyMC4wNDE0IDEyLjA0NzEgMjEuMDk3MSAxMS4yODgyQzIwLjQ2OTYgMTAuNzkxOCAxMy43NjU3IDYuMzIyNSAxMy41OSA2TDEzLjYxNSA2LjAyNDY0QzEyLjgzODIgNy4yNTc1IDcuNDMxNzkgMTAuNTA1NCA2LjEzMjUgMTAuNjQ3OUM2LjczNSAxMS4xMTkzIDE1LjIzMTggMTcuMDk1NyAxNS4zNzI1IDE3LjE3MjFDMTUuODM1IDE3LjQyMTQgMTUuMzk4NiAxNy43MDYxIDE0Ljk2NDMgMTcuNDQ4MkMxNC43MTMyIDE3LjI5OTMgMTEuNzU3NSAxNS4yMzM2IDExLjM4NjQgMTUuMDE1QzkuODE2NzkgMTQuMDkxNCA2Ljk3Njc5IDE0Ljc0NjggNiAxNS43NzQ2QzYuNjI3NSAxNi4yNzExIDEzLjIwMDQgMjAuNjc2OCAxMy4zNzYxIDIxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==' // Red marker

const MARKER_ALT: string =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNyA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC45MTY0IDIwLjc4MjhDMjYuMjM2MSAxOC42ODk3IDI3IDE2LjIwODYgMjcgMTMuNTQ4NEMyNyA2LjA2NTgyIDIwLjk1NTggMCAxMy41IDBDNi4wNDQxNiAwIDAgNi4wNjU4MiAwIDEzLjU0ODRDMCAxNi4yMDg2IDAuNzYzOTI5IDE4LjY4OTcgMi4wODM2MyAyMC43ODI4TDEzLjUgNDJMMjQuOTE2NCAyMC43ODI4WiIgZmlsbD0iIzAwMzY4RiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjM3NjEgMjFDMTQuNDQxMSAxOS44MjYxIDE5Ljc0MTggMTYuNTkyNSAyMS4wNDExIDE2LjQ1QzIwLjQzODYgMTUuOTc4MiAxMS42OTExIDkuOTcxNzkgMTEuNTUgOS44OTU3MUMxMS4yNzM5IDkuNzQ2NDMgMTEuNjcxOCA5LjMyMzkzIDEyLjAwNjQgOS41OTQ2NEMxMi4yMzMyIDkuNzc3ODYgMTUuMDMzNiAxMS42MTA3IDE1LjM4NSAxMS44NTkzQzE3LjE2ODkgMTMuMTE4OSAyMC4wNDE0IDEyLjA0NzEgMjEuMDk3MSAxMS4yODgyQzIwLjQ2OTYgMTAuNzkxOCAxMy43NjU3IDYuMzIyNSAxMy41OSA2TDEzLjYxNSA2LjAyNDY0QzEyLjgzODIgNy4yNTc1IDcuNDMxNzkgMTAuNTA1NCA2LjEzMjUgMTAuNjQ3OUM2LjczNSAxMS4xMTkzIDE1LjIzMTggMTcuMDk1NyAxNS4zNzI1IDE3LjE3MjFDMTUuODM1IDE3LjQyMTQgMTUuMzk4NiAxNy43MDYxIDE0Ljk2NDMgMTcuNDQ4MkMxNC43MTMyIDE3LjI5OTMgMTEuNzU3NSAxNS4yMzM2IDExLjM4NjQgMTUuMDE1QzkuODE2NzkgMTQuMDkxNCA2Ljk3Njc5IDE0Ljc0NjggNiAxNS43NzQ2QzYuNjI3NSAxNi4yNzExIDEzLjIwMDQgMjAuNjc2OCAxMy4zNzYxIDIxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==' // Blue marker

//
// == Interface
interface IGoogleMapServiceOptions {
  $ref: { current: Node }
  center: any
  zoom: number
}

//
//
export default class GoogleMapService {
  public onViewChanged: any
  public onItemSelect: any
  private map: any
  private service: any
  private markers: IDealerInfo[]
  private $markers: any
  private filterOnUpdate: boolean = false

  private prevLat: number
  private prevLng: number
  private prevZoom: number
  private origin: any
  private activeMarker: any

  // Constructor - initialize Google Map and Service for
  constructor(options: IGoogleMapServiceOptions) {
    Object.assign(this, options)

    const { $ref, zoom, center } = options
    const { maps } = window.google

    // Map creation instance
    this.map = new maps.Map($ref, {
      animatedZoom: false,
      center,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoom,
      zoomControl: !TOUCH_SUPPORTED,
      mapId: '7b6adf699f119442',
    })

    // Event listeners
    this.map.addListener('dragend', () => {
      if (this.filterOnUpdate) {
        this.filterMarkers(this.markers, false, false)
      }
    })

    this.map.addListener('zoom_changed', () => {
      if (this.filterOnUpdate) {
        this.filterMarkers(this.markers, false, false)
      }
    })

    this.service = new maps.places.PlacesService(this.map)
  }

  // Method: Remove all markers, and add markers
  public updateMarkers = (markers: IDealerInfo[]): void => {
    // Completely empty out all the markers
    this.markers.forEach((item) => this.$markers[item.dealer_number].setMap(null))
    this.markers = null
    // Add new list of markers
    this.addMarkers(markers)

    if (this.markers.length > 1) {
      // Need to refilter markers to make sure that marker numbers are still correct
      this.filterMarkers(markers, false, false)
    }
  }

  // Method: Add add a list of markers to the map
  public addMarkers = (markers: IDealerInfo[], callback?: () => void): void => {
    const { maps } = window.google

    // Custom marker data
    this.markers = markers

    // Google Maps marker data
    this.$markers = {}

    const HAS_MULTIPLE_MARKERS: boolean = markers.length > 1

    // Add markers to map and cache value by dealer location id for fast lookup
    markers.forEach((item: IDealerInfo) => {
      let marker
      const markerContent = document.createElement('img')
      markerContent.src = MARKER

      if (HAS_MULTIPLE_MARKERS) {
        marker = new maps.marker.AdvancedMarkerElement({
          map: this.map,
          content: this.setMarkerElement(this.alternativeMarker(item) ? MARKER_ALT : MARKER),
          position: { lat: Number(item.location_lat), lng: Number(item.location_long) },
          gmpClickable: true,
        })

        marker.addListener('click', () => {
          this.onItemSelect(item)
        })
      } else {
        marker = new maps.marker.AdvancedMarkerElement({
          map: this.map,
          content: this.setMarkerElement(MARKER),
          position: { lat: Number(item.location_lat), lng: Number(item.location_long) },
          gmpClickable: true,
        })
      }

      this.$markers[item.dealer_number] = marker
    })
    if (callback) {
      // Used to make sure that markers are set before actually starting the repositioning within bounds
      callback()
    }
  }

  // Method: Get all visible markers
  public showAllMarkers = (reposition: boolean = true): IDealerInfo[] => {
    return this.filterMarkers(this.markers, true, reposition)
  }

  // Method: Jump to position and zoom level; store previous values so can go back
  // TODO: Future improvement is to keep a stack of positions
  public jumpTo = (lat: number, lng: number, zoom: number) => {
    if (this.map) {
      this.prevZoom = this.map.getZoom()
      this.prevLat = this.map.getCenter().lat()
      this.prevLng = this.map.getCenter().lng()

      // We have to wait to store the values before updating...
      requestAnimationFrame(() => {
        this.map.setCenter({ lat, lng })
        this.map.setZoom(zoom)
      })
    }
  }

  // Method: Go back to previous value
  public goBack = () => {
    if (this.map) {
      if (this.prevZoom) {
        this.map.setZoom(this.prevZoom)
        this.prevZoom = null
      }
      if (this.prevLat && this.prevLng) {
        // There are states in which no marker can be shown because the map screen is too small
        // Return the state where all markers are shown
        this.filterMarkers(this.markers, true, true)
        this.map.setZoom(1)
        this.prevLat = null
        this.prevLng = null
      }

      if (this.filterOnUpdate) {
        requestAnimationFrame(() => {
          this.filterMarkers(this.markers, false, true)
        })
      }
    }
  }

  // Method: Set input markers to be in view
  public setMarkersInView = (markers: IDealerInfo[], reposition: boolean = false) => {
    return this.filterMarkers(markers, false, reposition)
  }

  // Method: Set state for a single marker
  public setMarkerState = (item: IDealerInfo, selected: boolean) => {
    const { maps } = window.google

    if (maps) {
      if (this.activeMarker) {
        this.activeMarker = null
      }

      const marker = this.$markers[item.dealer_number]

      if (marker) {
        if (selected) {
          this.activeMarker = marker
        }
      }
    }
  }

  // Method: Set filter on update value (to trigger updating list)
  public setFilterOnUpdate = (value: boolean): void => {
    this.filterOnUpdate = value
  }

  // Method: Search by location
  public search = (query: string, noResultsCallback?: () => void): void => {
    const { maps } = window.google

    if (query) {
      const request = {
        fields: ['name', 'geometry'],
        query,
      }

      this.service.findPlaceFromQuery(request, (results: any, status: any) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          if (results && results.length > 0) {
            const { location } = results[0].geometry
            const lat = location.lat()
            const lng = location.lng()

            this.origin = new maps.LatLng({ lat, lng })

            this.jumpTo(lat, lng, window.innerWidth < 768 ? 9 : 11)

            setTimeout(() => this.filterMarkers(this.markers, false, false), 150)
          }
        } else if (status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          // GoogleMaps didn't find anything, report error
          if (noResultsCallback) {
            noResultsCallback()
          }
        }
      })
    }
  }

  // Method: Set origin and sort markers by distance
  public setOrigin = (lat: number, lng: number): void => {
    const { maps } = window.google

    this.origin = new maps.LatLng({ lat, lng })

    this.jumpTo(lat, lng, 11)

    setTimeout(() => {
      this.filterMarkers(this.markers, false, false)
    }, 20)
  }

  // Method: set only the origin, without jumping
  public setOnlyOrigin = (lat: number, lng: number): void => {
    const { maps } = window.google
    this.origin = new maps.LatLng({ lat, lng })
  }

  // Method: Clear origin
  public clearOrigin = () => {
    this.origin = null

    this.markers.forEach((item: IDealerInfo) => {
      item.distance = null
    })
  }

  // Filter markers by dataset
  private filterMarkers = (
    data: IDealerInfo[],
    forceShow: boolean = false,
    reposition: boolean
  ): IDealerInfo[] => {
    const { maps } = window.google
    const visibleMarkers: IDealerInfo[] = []

    // Don't filter if there's an active marker
    if (this.activeMarker) {
      return
    }

    if (data && maps && this.map) {
      const bounds = new maps.LatLngBounds()

      data.forEach((item: IDealerInfo, index: number) => {
        const marker = this.$markers[item.dealer_number]

        // Check marker exists
        if (marker) {
          item.order = index + 1
          // Check if visible
          const mapBounds: any = this.map.getBounds()
          if (mapBounds && mapBounds.contains) {
            const visible: boolean = mapBounds.contains(marker.position)

            // Add visible dealer to list
            if (visible || forceShow) {
              if (this.origin) {
                const destination = new maps.LatLng({
                  lat: Number(item.location_lat),
                  lng: Number(item.location_long),
                })
                const distance = maps.geometry.spherical.computeDistanceBetween(
                  this.origin,
                  destination
                )

                item.distance = Math.round((distance / 1000) * 10) / 10
              }

              visibleMarkers.push(item)

              if (reposition) {
                bounds.extend(marker.position)
              }
            }
          }
        }
      })

      // Update filtered list
      if (this.onViewChanged && this.filterOnUpdate) {
        // Sort by distance if an origin (address) is provided
        if (this.origin) {
          visibleMarkers.sort((a, b) => {
            return a.distance - b.distance
          })
        }

        // Update markers
        visibleMarkers.forEach((item: IDealerInfo, index: number) => {
          const marker = this.$markers[item.dealer_number]

          if (marker) {
            item.order = index + 1
          }
        })

        // Update numbering...
        this.onViewChanged(visibleMarkers)
      }

      // If request to reposition on checking markers in view -> fit too bounds and clamp zoom level
      if (reposition) {
        this.map.fitBounds(bounds)

        if (this.map.getZoom() > 15) {
          this.map.setZoom(15)
        }
      }
    }

    return visibleMarkers
  }

  // Use a blue marker if not a sales dealer
  private alternativeMarker = (item: IDealerInfo) => !item.is_sales_dealer

  // Create a marker element
  private setMarkerElement = (icon: string) => {
    const markerElement = document.createElement('img')
    markerElement.src = icon

    return markerElement
  }
}
