import React, { useState } from 'react'
import { useRootState } from '~src/hooks/store'
import { currencyFormatter } from '~src/utils/number-formatter'
import ButtonArrow from '~svg/button-arrow.svg'
import { CarTradeInContactForm } from './CarTradeInContactForm'
import { CarTradeInVoucher } from './CarTradeInVoucher'
import { hasTradeInOffer } from '~src/utils/trade-in'

type Props = {
  dealersApiUrl: string
  quoteApiUrl: string
  apiRootUrl: string
  voucherFormApiUrl: string
  isConfiguratorFlow: boolean
  louwmanHubBrand?: string
  prcodeApi?: string
  afterModalClosing?: () => void
}

export const CarTradeInProposal = ({
  dealersApiUrl,
  quoteApiUrl,
  apiRootUrl,
  voucherFormApiUrl,
  isConfiguratorFlow,
  louwmanHubBrand,
  prcodeApi,
  afterModalClosing,
}: Props) => {
  const {
    tradeIn: { oldCar, newCar, tradeInQuote },
  } = useRootState()
  const [showContactForm, setShowContactForm] = useState(false)

  const tradeInValue = Number(tradeInQuote.trade_in_value)
  const totalTradeInValue = Number(tradeInQuote.total_trade_in_value)
  const newCarCalculatedPrice = Number(tradeInQuote.new_car_calculated_price)

  const extraTradeInValue = Number(tradeInQuote.extra_trade_in_value)
  const extraTradeInValueFixed = Number(tradeInQuote.extra_trade_in_value_fixed)

  const isTradeInValid = hasTradeInOffer(tradeInQuote, oldCar)

  const showVoucherForm = isTradeInValid && !isConfiguratorFlow

  return (
    <>
      <div className="car-trade-in-overview  box">
        <div className="car-trade-in-overview__table">
          <h3 className="h4">Inruilen</h3>

          {isTradeInValid ? (
            <>
              {!isConfiguratorFlow && (
                <div className="line-item">
                  <div>Jouw nieuwe Suzuki {newCar.model} is leverbaar vanaf</div>
                  <div>{currencyFormatter.format(Number(tradeInQuote.baseline_variant_price))}</div>
                </div>
              )}

              <div className="line-item">
                <div>
                  Autotelex inruilwaarde van jouw {oldCar.brand} {oldCar.model}
                </div>

                <div>
                  {!isConfiguratorFlow && '-'} {currencyFormatter.format(tradeInValue)}
                </div>
              </div>

              {extraTradeInValue > 0 && (
                <div className="line-item">
                  <div>Extra Suzuki inruilwaarde</div>
                  <div>
                    {!isConfiguratorFlow && '-'} {currencyFormatter.format(extraTradeInValue)}
                  </div>
                </div>
              )}

              {extraTradeInValueFixed > 0 && (
                <div className="line-item">
                  <div>{tradeInQuote.extra_trade_in_value_fixed_campaign_name}</div>
                  <div>
                    {!isConfiguratorFlow && '-'} {currencyFormatter.format(extraTradeInValueFixed)}
                  </div>
                </div>
              )}

              <hr className="m-0" />

              <div className="line-item">
                <div>Geschatte totale inruilwaarde</div>
                <div className="line-item__total">
                  {!isConfiguratorFlow && '-'} {currencyFormatter.format(totalTradeInValue)}
                </div>
              </div>

              <hr className="m-0" />

              {!isConfiguratorFlow && (
                <div className="line-item">
                  <div>Je rijdt daarmee jouw nieuwe Suzuki {newCar.model} vanaf</div>
                  <div>{currencyFormatter.format(newCarCalculatedPrice)}</div>
                </div>
              )}

              <em>Jouw inruilwaarde wordt berekend via Autotelex.</em>
            </>
          ) : (
            <div>
              Helaas is het niet mogelijk om een inruilwaarde te geven voor deze auto. Neem bij
              vragen contact op met{' '}
              <a href="/auto/dealers" className="button-link" target="blank">
                je Suzuki-dealer
              </a>
              .
            </div>
          )}
        </div>

        {!isConfiguratorFlow ? (
          !showContactForm &&
          newCarCalculatedPrice > 0 && (
            <div className="car-trade-in-overview__actions">
              <button
                className="button  button--small  button--primary"
                onClick={() => setShowContactForm(true)}
              >
                Neem contact met mij op
                <ButtonArrow className="icon" />
              </button>

              <a
                className="button  button--small  button--secondary"
                href={`/auto/dealers/?form_type=proefritaanvraag&model=${newCar.code}`}
                target="blank"
              >
                Proefrit aanvragen
                <ButtonArrow className="icon" />
              </a>
            </div>
          )
        ) : (
          <div className="car-trade-in-overview__actions">
            <button
              type="button"
              className="button button--small button--primary"
              onClick={() => afterModalClosing()}
            >
              Verder met samenstellen
              <ButtonArrow className="icon" />
            </button>
          </div>
        )}
      </div>

      {showContactForm && (
        <CarTradeInContactForm
          dealersApiUrl={dealersApiUrl}
          quoteApiUrl={quoteApiUrl}
          apiRootUrl={apiRootUrl}
          louwmanHubBrand={louwmanHubBrand}
          prcodeApi={prcodeApi}
        />
      )}

      {showVoucherForm && <CarTradeInVoucher voucherApiUrl={voucherFormApiUrl} />}
    </>
  )
}
