import queryString from 'query-string'
import { FacetName, facetNames, IFacetChoices, SortType, sortTypes } from '~src/api/types/occasion'
import { defaultBasicConfiguration } from '~src/store/configuration/initialState'
import {
  IBasicConfiguration,
  ICarConfiguration,
  IConfigurationState,
  ProductGroup,
} from '~src/store/configuration/types'
import { initialState as initialNavigationState } from '~src/store/navigation/navigationReducer'
import {
  FinancialPlan,
  financialPlans,
  INavigationState,
  StepName,
  steps,
} from '~src/store/navigation/types'
import { ITradeInCar, ITradeInState, ITradeInSuggestion } from '~src/store/trade-in/types'
import { getStringOrLastOfArray, objectsDifference } from '~src/utils/collection'
import { flattenObject, pruned, unflattenObject } from '~src/utils/object'
import { RootState } from '.'

export const serializeState = ({ navigation, configuration, tradeIn }: RootState) =>
  [
    serializeNavigation(navigation),
    serializeConfiguration(configuration),
    serializeTradeInCar(tradeIn),
    serializeTradeInNewCar(tradeIn),
  ]
    .filter(Boolean)
    .join('&')

export const serializeNavigation = (navigationState: INavigationState) => {
  const { prcode } = queryString.parse(queryString.extract(window.location.search || ''))

  return queryString.stringify({
    financeModal: navigationState.financeModal,
    step: navigationState.currentStep,
    plan: navigationState.financialPlan,
    prcode,
  })
}

const serializeTradeInCar = (tradeIn: ITradeInState) => {
  if (!tradeIn?.oldCar) return

  // Remove `alternative_versions` from the object used for query parameters.
  // Reason: we don't need it and it will just clutter the URL.
  const { alternative_versions: _, ...rest } = tradeIn.oldCar
  const flattened = flattenObject({ oldCar: rest })
  return queryString.stringify(flattened)
}

const serializeTradeInNewCar = (tradeIn: ITradeInState) => {
  if (!tradeIn?.newCar) return

  const flattened = flattenObject({ newCar: tradeIn.newCar })
  console.log(flattened)
  return queryString.stringify(flattened)
}

export const deserializeNavigation = (queryParams: string): INavigationState => {
  const queryParamsParsed = queryString.parse(queryParams)

  const step = getStringOrLastOfArray(queryParamsParsed.step) as StepName
  const financeModal = getStringOrLastOfArray(queryParamsParsed.financeModal)
  const plan = getStringOrLastOfArray(queryParamsParsed.plan) as FinancialPlan
  const isFinanceModal = JSON.parse(financeModal ?? 'false')

  return {
    financeModal: isFinanceModal,
    currentStep: isFinanceModal
      ? 'finance'
      : steps.includes(step)
      ? step
      : initialNavigationState.currentStep,
    financialPlan: financialPlans.includes(plan) ? plan : null,
  }
}

export const serializeConfiguration = (configuration: IConfigurationState) =>
  queryString.stringify(
    {
      model: configuration.model,
      edition: configuration.car.edition,
      color: configuration.car.color,
      accessories: configuration.car.accessoryCodes,
      packages: configuration.car.packageCodes,
      upsells: configuration.car.upsellCodes,
      ...objectsDifference(configuration.basic, defaultBasicConfiguration),
    },
    {
      arrayFormat: 'bracket',
    }
  )

export const deserializeConfiguration = (
  queryParams: string,
  modelsApiParams: string
): {
  model?: string
  basic: Partial<IBasicConfiguration>
  car: Partial<ICarConfiguration>
  productGroup?: ProductGroup
} => {
  const { model } = queryString.parse(queryParams, {})
  const { product_group } = queryString.parse(modelsApiParams)
  return {
    model: model as string,
    car: deserializeCarConfiguration(queryParams),
    basic: deserializeBasicConfiguration(queryParams),
    productGroup: product_group as ProductGroup,
  }
}

export const deserializeTradeInCar = (queryParams: string): ITradeInCar => {
  const queryParamsParsed = queryString.parse(queryParams)
  const oldCar = unflattenObject(queryParamsParsed).oldCar

  // Convert string booleans and numbers to actual data types.
  const isDamaged = oldCar?.isDamaged ? JSON.parse(oldCar.isDamaged as string) : false
  const isDealerMaintained = oldCar?.isDealerMaintained
    ? JSON.parse(oldCar.isDealerMaintained as string)
    : false
  const isSmokeCar = oldCar?.isSmokeCar ? JSON.parse(oldCar.isSmokeCar as string) : false
  const isCompleted = oldCar?.isCompleted ? JSON.parse(oldCar.isCompleted as string) : false
  const mileage = oldCar?.mileage ? Number(oldCar.mileage) : 0

  return oldCar && isCompleted
    ? {
        ...oldCar,
        isDamaged,
        isDealerMaintained,
        isSmokeCar,
        isCompleted,
        mileage,
      }
    : undefined
}

export const deserializeTradeInNewCar = (queryParams: string): ITradeInSuggestion => {
  const queryParamsParsed = queryString.parse(queryParams)
  return unflattenObject(queryParamsParsed).newCar
}

export const deserializeBasicConfiguration = (
  queryParams: string
): Partial<IBasicConfiguration> => {
  // Outlook Safelinks encodes `&` as `&amp;`, which breaks the query string parser (SUZSUP-619).
  // We might want to correctly encode/decode our URLs in the future.
  const validQueryParams = queryParams.replace(/&amp;/g, '&')

  const {
    claimFreeYears,
    creditOverride,
    downPayment,
    durationMonths,
    finalPayment,
    regularPriceOverride,
    taxScale,
    yearlyKilometers,
  } = queryString.parse(validQueryParams)

  return pruned({
    claimFreeYears: claimFreeYears ? +claimFreeYears : undefined,
    taxScale: taxScale ? +taxScale : undefined,
    downPayment: positiveNumberOrUndefined(downPayment),
    finalPayment: positiveNumberOrUndefined(finalPayment),
    durationMonths: durationMonths ? +durationMonths : undefined,
    yearlyKilometers: yearlyKilometers ? +yearlyKilometers : undefined,
    regularPriceOverride: positiveNumberOrUndefined(regularPriceOverride),
    creditOverride: positiveNumberOrUndefined(creditOverride),
  })
}

export const DURATION_MONTHS = [24, 36, 48, 60]
export const YEARLY_KILOMETERS = [10, 15, 20, 25, 30, 35]
export const CLAIM_FREE_YEARS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const sanitizeBasicConfiguration = (basic: IBasicConfiguration): IBasicConfiguration => ({
  claimFreeYears: CLAIM_FREE_YEARS.includes(basic.claimFreeYears) ? basic.claimFreeYears : 12,
  taxScale: basic.taxScale || 42,
  downPayment: basic.downPayment || 0,
  finalPayment: basic.finalPayment || 0,
  durationMonths: DURATION_MONTHS.includes(basic.durationMonths) ? basic.durationMonths : 48,
  yearlyKilometers: YEARLY_KILOMETERS.includes(basic.yearlyKilometers / 1000)
    ? basic.yearlyKilometers
    : 10000,
  regularPriceOverride: basic.regularPriceOverride || 0,
  creditOverride: basic.creditOverride || 0,
})

export const deserializeCarConfiguration = (queryParams: string): Partial<ICarConfiguration> => {
  const { color, accessories, packages, edition, upsells } = queryString.parse(queryParams, {
    arrayFormat: 'bracket',
  })
  return pruned({
    color: (color as string) ?? null,
    accessoryCodes: (accessories as string[]) || [],
    packageCodes: (packages as string[]) || [],
    upsellCodes: (upsells as string[]) || [],
    edition: edition as string,
  })
}

// Occasion
export const serializeOccasionState = ({
  page,
  sorting,
  facets,
}: {
  page: number
  sorting?: SortType
  facets: IFacetChoices
}) =>
  queryString.stringify(
    {
      page,
      sorting,
      ...(facets.Location?.zipCode && { zipcode: facets.Location.zipCode }),
      ...(facets.Location?.distance && { distance: facets.Location.distance }),
      ...(facets.Location?.lat && { lat: facets.Location.lat }),
      ...(facets.Location?.lng && { lng: facets.Location.lng }),
      ...Object.fromEntries(
        Object.entries(facets)
          .filter(([_id, facet]) => facet?.values?.length || facet?.range?.length)

          .map(([id, { values, range }]) => [id, range?.length ? range : values])
      ),
    },
    { arrayFormat: 'bracket' }
  )

const rangeKeys: FacetName[] = ['PriceLease', 'PriceOnline', 'Year', 'Mileage']

export const deserializeOccasionState = (queryParams: string) => {
  const queryParamsParsed = queryString.parse(queryParams, { arrayFormat: 'bracket' })

  const { page, ...facets } = queryParamsParsed
  const sorting = getStringOrLastOfArray(queryParamsParsed.sorting) as SortType

  return {
    page: Number(page ?? 0) ?? 0,
    sorting: sortTypes.includes(sorting) ? sorting : null,
    facets: {
      ...(facets.zipcode &&
        facets.distance &&
        facets.lat &&
        facets.lng && {
          Location: {
            zipCode: facets.zipcode as string,
            distance: facets.distance as string,
            lat: Number(facets.lat),
            lng: Number(facets.lng),
          },
        }),
      ...Object.fromEntries(
        Object.entries(facets)
          .filter((i) => facetNames.includes(i[0] as any))
          .map(([id, values]) => [
            id,
            rangeKeys.includes(id as FacetName) ? { range: values } : { values },
          ])
      ),
    },
  }
}

const positiveNumberOrUndefined = (value: string | string[]) => {
  if (!Array.isArray(value)) {
    return Number(value) >= 0 ? Number(value) : undefined
  }
}
