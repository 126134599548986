import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from '~src/common/Modal'
import { useRootState } from '~src/hooks/store'
import {
  removeNewCarWithTradeInQuote,
  removeTradeInCar,
  setTradeInModalStatus,
  updateTradeInCar,
} from '~src/store/trade-in/actions'
import { StatusName } from '~src/store/trade-in/types'
import { CarTradeInReceipt } from './CarTradeInReceipt'
import { CarTradeInModalForm } from './CarTradeInModalForm'
import { StepsNavigation } from './StepsNavigation'
import BackButton from '~src/common/BackButton'
import { NewCarModels } from './NewCarModels'
import { CarTradeInProposal } from './CarTradeInProposal'
import { hasTradeInOffer } from '~src/utils/trade-in'

type Props = {
  modelApiUrl?: string
  licensePlateApiUrl?: string
  dealersApiUrl?: string
  quoteApiUrl?: string
  voucherFormApiUrl?: string
  apiRootUrl?: string
  prcodeApi?: string
  louwmanHubBrand?: string
  onClose?: () => void
  isConfiguratorFlow?: boolean
}

export const CarTradeInModal = ({
  modelApiUrl,
  licensePlateApiUrl,
  dealersApiUrl,
  quoteApiUrl,
  voucherFormApiUrl,
  apiRootUrl,
  prcodeApi,
  louwmanHubBrand,
  onClose,
  isConfiguratorFlow = false,
}: Props) => {
  const dispatch = useDispatch()

  const {
    tradeIn: { status, oldCar, tradeInQuote },
  } = useRootState()

  function afterModalClosing() {
    if (hasTradeInOffer(tradeInQuote, oldCar)) {
      dispatch(
        updateTradeInCar({
          isCompleted: true,
          isSmokeCar: false,
          isDealerMaintained: false,
          isDamaged: false,
        })
      )
    } else {
      dispatch(removeTradeInCar())
      dispatch(removeNewCarWithTradeInQuote())
    }

    onClose?.()
    dispatch(setTradeInModalStatus(null))
  }

  function content(type: StatusName) {
    switch (type) {
      case 'request-trade-in-offer':
        return (
          <>
            <div className="flow">
              <h2 className="mb-0">Bereken jouw inruilwaarde</h2>
              <p className="mb-0">
                Ben je benieuwd naar de waarde van jouw huidige auto bij aankoop van een nieuwe
                Suzuki? Zie in twee simpele stappen direct jouw inruilaanbod! Om je goed van dienst
                te kunnen zijn hebben wij een aantal gegevens van je nodig.
              </p>
            </div>

            <CarTradeInModalForm
              licensePlateApiUrl={licensePlateApiUrl}
              prcodeApi={prcodeApi}
              apiRootUrl={apiRootUrl}
              isConfiguratorFlow={isConfiguratorFlow}
            />
          </>
        )

      case 'choose-new-car':
        return (
          <>
            <BackButton
              className="car-trade-in-modal__back-button"
              onClick={() => {
                dispatch(setTradeInModalStatus('request-trade-in-offer'))
              }}
            >
              <span>Ga Terug</span>
            </BackButton>
            <div className="flow">
              <h2 className="mb-0">Kies jouw Suzuki</h2>
              <p className="mb-0">In welk model ben je geïnteresseerd?</p>
            </div>

            <NewCarModels modelApiUrl={modelApiUrl} apiRootUrl={apiRootUrl} />

            <div className="car-trade-in-modal__disclaimer">
              <p>
                <em>
                  Voor het berekenen van de inruilwaarde wordt de basis uitvoering van een model
                  gebruikt.
                </em>
              </p>
            </div>
          </>
        )

      case 'get-trade-in-value':
        return (
          <>
            <BackButton
              className="car-trade-in-modal__back-button"
              onClick={() => {
                dispatch(removeTradeInCar())
                dispatch(removeNewCarWithTradeInQuote())
                dispatch(setTradeInModalStatus('request-trade-in-offer'))
              }}
            >
              <span>Opnieuw beginnen</span>
            </BackButton>

            <div className="flow">
              <h2 className="mb-0">Jouw inruilwaarde</h2>
              <p className="mb-0">Neem bij vragen contact op met de Suzuki-dealer.</p>
            </div>

            <CarTradeInProposal
              dealersApiUrl={dealersApiUrl}
              quoteApiUrl={quoteApiUrl}
              apiRootUrl={apiRootUrl}
              voucherFormApiUrl={voucherFormApiUrl}
              louwmanHubBrand={louwmanHubBrand}
              prcodeApi={prcodeApi}
              isConfiguratorFlow={isConfiguratorFlow}
              afterModalClosing={afterModalClosing}
            />

            <div className="car-trade-in-modal__disclaimer">
              <p>
                <em>
                  <strong>Privacyverklaring</strong>
                  Als je jouw voucher aanvraagt ga je akkoord met onze privacyverklaring. De door
                  jou verstrekte gegevens worden uitsluitend gebruikt voor de door jou gevraagde
                  informatie.
                </em>
              </p>

              <p>
                <em>
                  <strong>Bepaling van jouw inruilwaarde</strong>
                  Op basis van de door jou opgegeven informatie wordt een zo nauwkeurig mogelijke
                  inschatting gemaakt van de inruilwaarde van jouw auto. De inruilwaarde van jouw
                  auto is slechts een indicatie en onder voorbehoud van inspectie en technische
                  keuring van jouw auto bij je Suzuki-dealer. Ga voor jouw persoonlijk aanbod naar
                  je Suzuki-dealer.
                </em>
              </p>
            </div>
          </>
        )

      case 'no-trade-in-offer':
        return (
          <>
            <BackButton
              className="car-trade-in-modal__back-button"
              onClick={() => {
                dispatch(removeTradeInCar())
                dispatch(removeNewCarWithTradeInQuote())
                dispatch(setTradeInModalStatus('request-trade-in-offer'))
              }}
            >
              <span>Opnieuw beginnen</span>
            </BackButton>

            <div className="car-trade-in-overview  box">
              <div className="car-trade-in-overview__table">
                <h3 className="h4">Inruilen</h3>
                <div>
                  Helaas is het niet mogelijk om een inruilwaarde te geven voor deze auto. Neem bij
                  vragen contact op met{' '}
                  <a href="/auto/dealers" className="button-link" target="blank">
                    je Suzuki-dealer
                  </a>
                  .
                </div>
              </div>
            </div>

            <div className="car-trade-in-modal__disclaimer">
              <p>
                <em>
                  <strong>Privacyverklaring</strong>
                  Als je jouw voucher aanvraagt ga je akkoord met onze privacyverklaring. De door
                  jou verstrekte gegevens worden uitsluitend gebruikt voor de door jou gevraagde
                  informatie.
                </em>
              </p>

              <p>
                <em>
                  <strong>Bepaling van jouw inruilwaarde</strong>
                  Op basis van de door jou opgegeven informatie wordt een zo nauwkeurig mogelijke
                  inschatting gemaakt van de inruilwaarde van jouw auto. De inruilwaarde van jouw
                  auto is slechts een indicatie en onder voorbehoud van inspectie en technische
                  keuring van jouw auto bij je Suzuki-dealer. Ga voor jouw persoonlijk aanbod naar
                  je Suzuki-dealer.
                </em>
              </p>
            </div>
          </>
        )

      default:
        return null
    }
  }

  return (
    <Modal
      open={!!status}
      onClose={afterModalClosing}
      className="modal-full-screen car-trade-in-modal"
    >
      <div className="car-trade-in-modal__header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <StepsNavigation isConfiguratorFlow={isConfiguratorFlow} />
            </div>
          </div>
        </div>
      </div>
      <div className="container car-trade-in-modal__body">
        <div className="row">
          <div className="col-lg-8 car-trade-in-modal__content">{content(status)}</div>

          <div className="col-lg-4  car-trade-in-modal__aside">
            {oldCar && <CarTradeInReceipt />}

            <div className="dealer-contact">
              <div>
                <p>Heb je vragen?</p>
                <p>
                  Neem contact op met{' '}
                  <a href="/auto/dealers" target="blank" className="button-link">
                    je dealer
                  </a>
                </p>
              </div>
            </div>

            <ul className="usp-list">
              <li>Direct jouw inruilwaarde</li>
              <li>Ruil je auto in voor een nieuwe Suzuki</li>
              <li>100% vrijblijvend</li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  )
}
