import React, { CSSProperties } from 'react'
import { useDispatch } from 'react-redux'
import { useRootState } from '~src/hooks/store'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'
import { StatusName } from '~src/store/trade-in/types'
import CheckIconWhite from '~svg/checkmark-white.svg'

const stepLabel = (step: StatusName) => {
  switch (step) {
    case 'request-trade-in-offer':
      return 'inruil'
    case 'choose-new-car':
      return 'model'
    case 'get-trade-in-value':
      return 'inruilwaarde'
    default:
      return 'inruil'
  }
}

const getActiveStep = (currentStep: StatusName, steps: StatusName[]): number =>
  currentStep === 'request-trade-in-offer'
    ? 0
    : currentStep === 'no-trade-in-offer'
      ? 3
      : steps.indexOf(currentStep)

export const StepsNavigation = ({ isConfiguratorFlow }: { isConfiguratorFlow: boolean }) => {
  const {
    tradeIn: { status },
  } = useRootState()
  const dispatch = useDispatch()

  const steps: StatusName[] = !isConfiguratorFlow
    ? ['request-trade-in-offer', 'choose-new-car', 'get-trade-in-value']
    : ['request-trade-in-offer', 'get-trade-in-value']

  return (
    <div
      className="stepper"
      style={
        {
          '--step-count': steps.length,
        } as CSSProperties
      }
    >
      {steps.map((name, i) => (
        <Step
          key={name}
          label={stepLabel(name)}
          step={i}
          current={getActiveStep(status, steps)}
          onClickCompleted={() => {
            dispatch(setTradeInModalStatus(name))
          }}
        />
      ))}
    </div>
  )
}

const Step = ({
  step,
  current,
  label,
  onClickCompleted,
}: {
  step: number
  current: number
  label: string
  onClickCompleted: () => void
}) => {
  const variant = current > step ? 'completed' : step > current ? 'todo' : 'current'

  return (
    <div
      className={`stepper__step stepper__step--${variant}`}
      onClick={variant === 'completed' ? onClickCompleted : undefined}
      style={
        {
          '--step-line-pos': `calc(${step} * (100% / (var(--step-count) - 1)))`,
        } as CSSProperties
      }
      data-testid="stepper-step"
    >
      <div className="stepper__circle">
        {variant === 'completed' ? <CheckIconWhite height={12} width={12} /> : step + 1}
      </div>
      <div className="stepper__label">{label}</div>
    </div>
  )
}
