import React, { useEffect, useState } from 'react'
import { apiGet } from '~src/api/client'
import { IEdition } from '~src/api/types/configurator'
import SpecificationsTable from '~src/apps/Comparison/SpecificationsTable'
import Modal from '~src/common/Modal'

type Props = {
  editionDetailUrl: string
  showImage?: boolean
}

const SpecificationsModal = ({ editionDetailUrl, showImage }: Props): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [editions, setEditions] = useState<IEdition[]>(null)

  useEffect(() => {
    // If modal is opened and editionsInfo is not yet set, retrieve from API
    if (!editions && modalIsOpen) {
      apiGet<IEdition>(editionDetailUrl).then(({ data }) => {
        setEditions([data])
      })
    }
  }, [modalIsOpen, editions])

  return (
    <>
      <button onClick={() => setModalIsOpen(true)}>Specificaties</button>

      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        className="specifications-modal modal-lg-wide"
      >
        {editions?.[0] ? (
          <>
            <h5 className="specifications-modal__title text-center">Specificaties</h5>
            <h3 className="text-center">{editions[0].name}</h3>

            {showImage && (
              <img
                src={editions[0].image_url}
                width={360}
                height={180}
                className="img my-3 mx-auto d-block"
                style={{ zIndex: 1 }}
              />
            )}
            <SpecificationsTable editions={editions} multipleColumns={false} />
          </>
        ) : (
          'Specificaties aan het laden...'
        )}
      </Modal>
    </>
  )
}

export default SpecificationsModal
