import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet, apiPostQuote } from '~src/api/client'
import { IModelProducts } from '~src/api/types/configurator'
import { useRootState } from '~src/hooks/store'
import { setNewCarWithTradeInQuote, setTradeInModalStatus } from '~src/store/trade-in/actions'
import ButtonArrow from '~svg/button-arrow.svg'
import Skeleton from '../Configurator/components/Skeleton'

type Props = {
  modelApiUrl: string
  apiRootUrl: string
}

export const NewCarModels = ({ modelApiUrl, apiRootUrl }: Props) => {
  const [models, setModels] = useState<IModelProducts[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const {
    tradeIn: { oldCar },
  } = useRootState()

  useEffect(() => {
    apiGet<IModelProducts[]>(modelApiUrl)
      .then((result) => setModels(result.data))
      .catch((error) =>
        console.error('Something went wrong in retrieving the disclaimer text: ', error)
      )
      .finally(() => setIsLoading(false))
  }, [])

  const handleSelectModel = async (model: IModelProducts) => {
    const result = await apiPostQuote({
      apiBaseUrl: apiRootUrl,
      oldCar,
      newCarCode: model.code,
      newCarEditionCode: model.baseline_variant.code,
    })

    const tradeInQuote = result.data

    dispatch(
      setNewCarWithTradeInQuote({
        newCar: {
          code: model.code,
          model: model.name,
          edition: {
            code: model.baseline_variant.code,
            name: model.baseline_variant.name,
            price: model.baseline_variant.from_price,
          },
          image_url: model.image_url,
        },
        tradeInQuote,
      })
    )
    dispatch(setTradeInModalStatus('get-trade-in-value'))
  }

  return (
    <div className="model-grid">
      {!isLoading
        ? models.map((model, i) => (
            <div className="model-card" key={`${model.name}-${i}`}>
              {model.ribbon && (
                <div className="model-card__ribbon">
                  <span className="label label--red">{model.ribbon}</span>
                </div>
              )}

              <div className="model-card__body">
                <figure className="model-card__figure" onClick={() => handleSelectModel(model)}>
                  <img src={model.image_url} alt={model.name} />
                </figure>

                <h2 className="model-card__title h5">{model.name}</h2>
              </div>

              <div className="model-card__actions">
                <button className="button button--primary" onClick={() => handleSelectModel(model)}>
                  Kies {model.name}
                  <ButtonArrow className="icon" />
                </button>
              </div>
            </div>
          ))
        : [...Array(6)].map((_, i) => <NewCarModelsCardSkeleton key={i} />)}
    </div>
  )
}

const NewCarModelsCardSkeleton = (): JSX.Element => (
  <div className="skeleton-new-car-model-card">
    <Skeleton className="skeleton-new-car-model-card__image" />
    <Skeleton className="skeleton-new-car-model-card__title" />
  </div>
)
