import React, { useState } from 'react'
import InputCheckbox from '~src/common/InputCheckbox'
import InputText from '~src/common/InputText'
import { handleFormSubmit } from '~src/dom_components/forms'
import { useRootState } from '~src/hooks/store'
import { currencyFormatter, numberFormatter } from '~src/utils/number-formatter'
import ButtonArrow from '~svg/button-arrow.svg'

type Props = {
  voucherApiUrl: string
}

export const CarTradeInVoucher = ({ voucherApiUrl }: Props) => {
  const [success, setSuccess] = useState(false)
  const {
    tradeIn: { oldCar, newCar, tradeInQuote },
  } = useRootState()

  const totalTradeInValue = Number(tradeInQuote.total_trade_in_value)

  const handleFormSuccess = () => {
    setSuccess(true)
  }

  return (
    <div className="car-trade-in-voucher">
      <figure>
        {totalTradeInValue > 0 && (
          <figcaption>
            <span className="label  label--red">
              <span>geschatte inruilwaarde</span>
              {currencyFormatter.format(totalTradeInValue)}
            </span>
          </figcaption>
        )}
        <img src={newCar.image_url} alt={newCar.model} />
      </figure>
      <div>
        <h3 className="h4 mb-0">Inruilwaarde voucher</h3>
        <p className="mb-0">Vraag de voucher aan en ontvang dit aanbod per mail. </p>

        {success ? (
          <div className="success-message">
            <p>Je ontvangt de voucher binnen enkele ogenblikken per e-mail.</p>
          </div>
        ) : (
          <form
            onSubmit={(e) => handleFormSubmit(e.nativeEvent, handleFormSuccess)}
            data-api-url={voucherApiUrl}
          >
            <div className="js-form-fields">
              <div>
                <InputText
                  label="E-mailadres"
                  id="trade_in_voucher_email"
                  name="email_address"
                  type="email_address"
                />
                <button className="button  button--primary" type="submit">
                  <ButtonArrow className="icon" />
                </button>
              </div>

              <InputCheckbox id="trade_in_commercial_use" name="commercial_use">
                Ja, Suzuki/Nimag mag mij op de hoogte houden van acties, aanbiedingen en
                service-berichten
              </InputCheckbox>

              <p className="text-small">
                Als je jouw aanvraag verstuurt ga je akkoord met onze privacyverklaring. De door jou
                verstrekte gegevens worden uitsluitend gebruikt voor het door jou gevraagde contact
                of de door jou gevraagde informatie.
              </p>

              <div className="form-error error js-form-error"></div>

              <input type="hidden" value="car" name="product_group" />

              {/* Old car data */}
              <input type="hidden" value={oldCar.license_plate} name="license_plate" />
              <input type="hidden" value={oldCar.mileage} name="mileage" />
              <input type="hidden" value={oldCar.brand} name="brand" />
              <input type="hidden" value={oldCar.model} name="model" />
              <input type="hidden" value={oldCar.version} name="version" />
              <input type="hidden" value={oldCar.fuel_type} name="fuel_type" />

              {/* New car data */}
              <input type="hidden" value={newCar.code} name="model_codes" />
              <input type="hidden" value={newCar.edition.code} name="model_edition_code" />
              <input type="hidden" value={newCar.model} name="vehicle_model" />

              {/* TradInQuote data */}
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.baseline_variant_price))}
                name="baseline_variant_price"
              />
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.trade_in_value))}
                name="trade_in_value"
              />
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.total_trade_in_value))}
                name="total_trade_in_value"
              />
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.new_car_calculated_price))}
                name="new_car_calculated_price"
              />
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.extra_trade_in_value))}
                name="extra_trade_in_value"
              />
              <input
                type="hidden"
                value={numberFormatter.format(Number(tradeInQuote.extra_trade_in_value_fixed))}
                name="extra_trade_in_value_fixed"
              />
              <input
                type="hidden"
                value={tradeInQuote.extra_trade_in_value_fixed_campaign_name}
                name="extra_trade_in_value_fixed_campaign_name"
              />
            </div>
          </form>
        )}
        <p className="text-small">
          <em>Dit scherpe aanbod is 2 weken geldig.</em>
        </p>
      </div>
    </div>
  )
}
