import { useMapsLibrary } from '@vis.gl/react-google-maps'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { IFacetChoices, IFacetState, IOccasionResponse, IOption } from '~src/api/types/occasion'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import { formattedOptionName } from '~src/apps/Occasion/occasion-label'
import Accordion from '~src/common/Accordion'
import CheckboxGroup from '~src/common/CheckboxGroup'
import MinMaxSelect from '~src/common/MinMaxSelect'
import SelectInput from '~src/common/SelectInput'
import { sanitizeZipCode, validZipcode } from '~src/utils/address'
import { sum } from '~src/utils/math'
import EmailIcon from '~svg/mail.svg'
import SearchIcon from '~svg/search.svg'

const Filters = ({
  data,
  state,
  onChange,
  dealers,
  fixedDealer,
  loading,
  onMailButtonClick,
}: {
  data: IOccasionResponse
  state: IFacetChoices
  onChange: (f: IFacetChoices) => void
  dealers: IDealerInfo[]
  fixedDealer: number
  loading: boolean
  onMailButtonClick: () => void
}): JSX.Element => {
  const [zipCode, setZipCode] = useState<string>(state.Location?.zipCode ?? '')
  const [locationError, setLocationError] = useState<boolean>(false)

  const dealersListFiltered = data.facets.ClientId.options.filter(atLeastOne).sort((a, b) => {
    const nameA = dealers.find((d) => d.dealer_number.toString() === a.name)?.name ?? 'Onbekend'
    const nameB = dealers.find((d) => d.dealer_number.toString() === b.name)?.name ?? 'Onbekend'
    return nameA.localeCompare(nameB)
  })

  // Add default min/max for "bouwjaar" to be consistent
  const yearOptions = [
    {
      name: '0',
      value: '0',
      selected: false,
      count: 0,
    },
    ...data.facets.Year.options,
  ]

  const LOCATION_DISTANCE_OPTIONS = ['20', '30', '40', '50', '100', '200', '300']

  const geocodingApiLoaded = useMapsLibrary('geocoding')
  const [geocodingService, setGeocodingService] = useState<google.maps.Geocoder>()

  useEffect(() => {
    if (!geocodingApiLoaded) return

    setGeocodingService(new window.google.maps.Geocoder())
  }, [geocodingApiLoaded])

  const handleLocationChange = () => {
    const isValid = validZipcode(zipCode)
    setLocationError(!isValid)

    if (isValid && geocodingService) {
      geocodingService
        .geocode({ address: zipCode + ', Netherlands' })
        .then((results) => {
          if (results) {
            const { lat, lng } = results.results[0].geometry.location

            onChange({
              ...state,
              Location: {
                zipCode: zipCode,
                distance: state.Location?.distance || LOCATION_DISTANCE_OPTIONS[0],
                lat: lat(),
                lng: lng(),
              },
            })
          }
        })
        .catch((error) => {
          console.error('Geocoding error:', error)
        })
    }
  }

  return (
    <div>
      <Accordion title="Zoekopdracht naar jezelf mailen?" alwaysOpen variant="secondary">
        <div className="mb-3">
          <button
            type="button"
            className="button button--primary w-100"
            onClick={() => onMailButtonClick()}
          >
            Mail zoekopdracht
            <EmailIcon className="icon" />
          </button>
        </div>
      </Accordion>
      <Accordion title="Locatie" defaultOpen variant="secondary">
        <div className={clsx('form-group', locationError && 'is-invalid')}>
          <div className="input-group mb-3">
            <input
              className="form-control input-text"
              id="zipCode"
              name="zipCode"
              placeholder="Zoek op postcode"
              value={zipCode}
              onChange={(e) => setZipCode(sanitizeZipCode(e.target.value))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLocationChange()
                }
              }}
              disabled={loading}
            />
            <div className="input-group__endAdornment">
              <button
                type="button"
                className="button button--icon p-0 d-flex"
                onClick={handleLocationChange}
              >
                <SearchIcon className="icon" />
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <SelectInput
            value={state.Location?.distance}
            options={LOCATION_DISTANCE_OPTIONS.map((o) => ({
              name: o,
              value: o,
              count: 0,
            }))}
            disabled={loading || !state.Location}
            getLabel={(i) => `Tot ${i.name} km`}
            getValue={(i) => i.value}
            onChange={(e) =>
              onChange({
                ...state,
                Location: { ...state.Location, distance: e },
              })
            }
          />
        </div>
      </Accordion>
      <Accordion title={fixedDealer ? 'Merk en model' : 'Model'} defaultOpen variant="secondary">
        {fixedDealer && (
          <div className="mb-3">
            <SelectInput
              value={state.Brand?.values?.[0]}
              options={data.facets.Brand.options
                .filter(atLeastOne)
                .sort((a, b) => a.name.localeCompare(b.name))}
              nullLabel="Kies een merk"
              getLabel={(i) => `${i.name} (${i.count})`}
              getValue={(i) => i.value}
              onChange={(e) => onChange({ ...state, Brand: e ? { values: [e] } : undefined })}
              disabled={loading}
              size="medium"
            />
          </div>
        )}
        <div className="mb-3">
          <SelectInput
            value={state.Model?.values?.[0]}
            options={data.facets.Model.options
              .filter(atLeastOne)
              .sort((a, b) => a.name.localeCompare(b.name))}
            nullLabel="Kies een model"
            getLabel={(i) => `${i.name} (${i.count})`}
            getValue={(i) => i.value}
            onChange={(e) => onChange({ ...state, Model: e ? { values: [e] } : undefined })}
            disabled={loading}
            size="medium"
          />
        </div>
      </Accordion>
      <Accordion title="Bouwjaar" defaultOpen variant="secondary">
        <MinMaxSelect
          value={minMaxFromRange(state.Year)}
          options={minMaxOptions(ordered(yearOptions))}
          getLabel={minMaxZeroLabel('Minimum (jaar)', 'Maximum (jaar)', (i) => i.value, true)}
          getValue={(i) => (i.value === '0' ? '' : i.value)}
          onChange={(range) => onChange({ ...state, Year: nullableRange(range) })}
          disabled={loading}
        />
      </Accordion>
      <Accordion title="Kilometerstand" defaultOpen variant="secondary">
        <MinMaxSelect
          value={minMaxFromRange(state.Mileage)}
          options={minMaxOptions(ordered(data.facets.Mileage.options))}
          getLabel={minMaxZeroLabel('Minimum (km)', 'Maximum (km)', (i) =>
            formattedOptionName('Mileage', i.name)
          )}
          getValue={(i) => (i.value === '0' ? '' : i.value)}
          onChange={(range) => onChange({ ...state, Mileage: nullableRange(range) })}
          disabled={loading}
        />
      </Accordion>
      <Accordion title="Transmissie" defaultOpen variant="secondary">
        <CheckboxGroup
          name={data.facets.Transmission.name}
          value={state.Transmission?.values}
          options={data.facets.Transmission.options.filter(atLeastOne)}
          getLabel={(i) => `${i.name} (${i.count})`}
          getValue={(i) => i.value}
          onChange={(e) =>
            onChange({ ...state, Transmission: e.length ? { values: e } : undefined })
          }
          disabled={loading}
        />
      </Accordion>
      <Accordion title="Budget" defaultOpen variant="secondary">
        <MinMaxSelect
          value={minMaxFromRange(state.PriceOnline)}
          options={minMaxOptions(ordered(data.facets.PriceOnline.options))}
          getLabel={minMaxZeroLabel(
            'Van geen minimum',
            'Tot geen maximum',
            (i, isMin) => `${isMin ? 'Van' : 'Tot'} ${formattedOptionName('PriceOnline', i.name)}`
          )}
          getValue={(i) => (i.value === '0' ? '' : i.value)}
          onChange={(range) => onChange({ ...state, PriceOnline: nullableRange(range) })}
          disabled={loading}
        />
      </Accordion>
      {!fixedDealer && (
        <Accordion title="Dealer" defaultOpen variant="secondary">
          <div className="mb-3">
            <SelectInput
              value={state.ClientId?.values?.[0]}
              options={dealersListFiltered}
              nullLabel={`Alle dealers`}
              getLabel={(i) =>
                `${
                  dealers.find((d) => d.dealer_number.toString() === i.name)?.name ?? 'Onbekend'
                } (${i.count})`
              }
              getValue={(i) => i.value}
              onChange={(e) => onChange({ ...state, ClientId: e ? { values: [e] } : undefined })}
              disabled={loading}
              size="medium"
            />
          </div>
        </Accordion>
      )}
      <Accordion title="Brandstof" variant="secondary">
        <CheckboxGroup
          name={data.facets.FuelType.name}
          value={state.FuelType?.values}
          options={data.facets.FuelType.options.filter(atLeastOne)}
          getLabel={(i) => `${i.name} (${i.count})`}
          getValue={(i) => i.value}
          onChange={(e) => onChange({ ...state, FuelType: e.length ? { values: e } : undefined })}
          disabled={loading}
        />
      </Accordion>
      <Accordion title="Exterieur kleur" variant="secondary">
        <div className="mb-3">
          <SelectInput
            value={state.ColorNumber?.values?.[0]}
            options={data.facets.ColorNumber.options
              .filter(atLeastOne)
              .sort((a, b) => a.name.localeCompare(b.name))}
            nullLabel="Alle kleuren"
            getLabel={(i) => `${i.name} (${i.count})`}
            getValue={(i) => i.value}
            onChange={(e) => onChange({ ...state, ColorNumber: e ? { values: [e] } : undefined })}
            disabled={loading}
            size="medium"
          />
        </div>
      </Accordion>
      <Accordion title="Aantal deuren" variant="secondary">
        <div className="mb-3">
          <SelectInput
            value={state.Doors?.values[0]}
            options={data.facets.Doors.options
              .filter(atLeastOne)
              .sort((a, b) => a.name.localeCompare(b.name))}
            nullLabel="Alle soorten"
            getLabel={(i) => `${i.name} (${i.count})`}
            getValue={(i) => i.value}
            onChange={(e) => onChange({ ...state, Doors: e ? { values: [e] } : undefined })}
            disabled={loading}
            size="medium"
          />
        </div>
      </Accordion>
    </div>
  )
}

export default Filters

/**
 * @param options an ordered array of options
 */
const minMaxOptions = (options: IOption[]) => {
  const total = sum(options, (o) => o.count)
  return {
    min: options
      .filter((o) => o.value !== 'overig')
      .reduce(
        ({ sum, list }, option) => ({
          sum: sum - option.count,
          list: [...list, { ...option, count: sum }],
        }),
        { sum: total, list: [] }
      )
      .list.filter((o) => o.count > 0 || o.value === '0'),
    max: options
      .filter((o) => o.value !== 'overig')
      .reduce(
        ({ sum, list }, option) => ({
          sum: sum + option.count,
          list: [...list, { ...option, count: sum }],
        }),
        { sum: 0, list: [] }
      )
      .list.filter((o) => o.count > 0 || o.value === '0'),
  }
}

const minMaxFromRange = (facetState: IFacetState) => ({
  min: facetState?.range?.[0],
  max: facetState?.range?.slice(-1)[0],
})

const nullableRange = ({ min, max }: { min: string; max: string }) =>
  min === '' && max === '' ? undefined : { range: [min ?? '', max ?? ''] }

const minMaxZeroLabel =
  (
    zeroMinLabel: string,
    zeroMaxLabel: string,
    getName: (i: IOption, isMin: boolean) => string,
    hideCount?: boolean
  ) =>
  (i: IOption, isMin: boolean) =>
    i.value === '0'
      ? isMin
        ? zeroMinLabel
        : zeroMaxLabel
      : `${getName(i, isMin)} ${!hideCount ? `(${i.count})` : ''}`

const atLeastOne = (option: IOption) => option.count > 0 || option.value === '0'

/**
 * Returns an ordered option array of numeric values.
 *
 * In the comparison, this function first checks if both option values are numbers.
 * If so, it is ordered by the value as number, and otherwise alphabetically.
 *
 * This is necessary because all values are stored as strings, but sometimes might contain "overig" etc.
 */
const ordered = (options: IOption[]) =>
  [...options].sort((a, b) =>
    Number(a.value) && Number(b.value)
      ? Number(a.value) - Number(b.value)
      : a.value.localeCompare(b.value)
  )