import clsx from 'clsx'
import React, { useState } from 'react'

export function FullColorLineUp() {
  const [selectedColor, setSelectedColor] = useState<color | null>(VITARA_EDITION_COLORS[0])

  return (
    <div className="full-color-lineup">
      <picture className="full-color-lineup__background">
        <source
          media="(max-width: 768px)"
          srcSet="/static/suzuki/images/full_color_lineup/color_back_mobile.png"
        />
        <img src="/static/suzuki/images/full_color_lineup/color_back.png" alt="" />
      </picture>
      <div className="full-color-lineup__content">
        <div className="container">
          <h1>Full Color Lineup</h1>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="full-color-lineup__list">
                {VITARA_EDITION_COLORS.map((color) => (
                  <button
                    type="button"
                    key={color.id}
                    className={clsx(
                      'full-color-lineup__color',
                      `full-color-lineup__color--${color.id}`,
                      selectedColor === color && 'selected'
                    )}
                    onClick={() => setSelectedColor(color)}
                  ></button>
                ))}
              </div>
            </div>
            <div className="col-md-7">
              <figure className="full-color-lineup__visual">
                <img
                  src={`/static/suzuki/images/full_color_lineup/color/${selectedColor.id}.png`}
                  alt={selectedColor.name}
                />
                <img src="/static/suzuki/images/full_color_lineup/color_stand.png" alt="Stand" />
              </figure>
              <p className="full-color-lineup__name">{selectedColor.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type color = {
  name: string
  id: string
}

const VITARA_EDITION_COLORS: color[] = [
  {
    id: 'fdc',
    name: 'Land Breeze Green Pearl Metallic × Bluish Black Pearl',
  },
  {
    id: 'e86',
    name: 'Opulent Red Pearl Metallic × Bluish Black Pearl',
  },
  {
    id: 'e5g',
    name: 'Arctic White Pearl × Bluish Black Pearl',
  },
  {
    id: 'e85',
    name: 'Splendid Silver Pearl Metallic × Bluish Black Pearl',
  },
  {
    id: 'ffz',
    name: 'Grandeur Grey Pearl Metallic × Bluish Black Pearl',
  },
  {
    id: 'wa1',
    name: 'Land Breeze Green Pearl Metalic',
  },
  {
    id: 'wbh',
    name: 'Celestial Blue Pearl Metallic',
  },
  {
    id: 'zhj',
    name: 'Arctic White Pearl',
  },
  {
    id: 'wbe',
    name: 'Splendid Silver Pearl Metallic',
  },
  {
    id: 'wbf',
    name: 'Grandeur Grey Pearl Metallic',
  },
  {
    id: 'wb3',
    name: 'Bluish Black Pearl',
  },
]
