import { useEffect, useMemo, useState } from 'react'
import { fallbackImageCommerce } from '~src/utils/image'

type Product = {
  id: string
  title: string
  price: number
  image: string
  attributes: any
}

export type SelectedOptions = {
  type: string | null
  model: string | null
  year: string | null
}

export const useTweakwiseApi = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [properties, setProperties] = useState<any>(null)
  const [facets, setFacets] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selected, setSelected] = useState<SelectedOptions>({
    type: null,
    model: null,
    year: null,
  })

  const dependencyMap: SelectedOptions = {
    type: null,
    model: 'type',
    year: 'model',
  }

  const configurator = useMemo(() => {
    return facets.map((o: any) => {
      // @ts-ignore
      const dependency = dependencyMap[o.facetsettings.urlkey]

      if (dependency !== null) {
        // @ts-ignore
        const value = selected[dependency]

        return {
          dependency,
          facet: o,
          attributes: o.attributes
            .filter((attr: any) => attr.title.includes(`${value}///`))
            .map((attr: any) => ({
              title: attr.title.replace(`${value}///`, ''),
              value: attr.title,
              isselected: attr.isselected,
              nrofresults: attr.nrofresults,
            })),
          enabled: value !== null,
        }
      }
      return {
        dependency,
        facet: o,
        attributes: o.attributes.map((attr: any) => ({
          title: attr.title,
          value: attr.title,
          isselected: attr.isselected,
          nrofresults: attr.nrofresults,
        })),
        enabled: true,
      }
    })
  }, [facets, dependencyMap, selected])

  const fetchData = async (selection: string = '') => {
    try {
      const response = await fetch(
        'https://gateway.tweakwisenavigator.net/navigation/5427f649?tn_cid=1&format=json' +
          selection
      )
      const data = await response.json()

      const productData = data.items.map((item: any) => ({
        id: item.id,
        title: item.title,
        price: parseFloat(item.price),
        image: item.image || fallbackImageCommerce,
        attributes: item.attributes,
      }))

      setProducts(productData)
      setProperties(data.properties)
      setFacets(data.facets)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  return {
    fetchData,
    configurator,
    products,
    properties,
    facets,
    loading,
    selected,
    setSelected,
  }
}
