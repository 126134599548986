/** Removes all undefined and null properties */
export const pruned = <T>(input: T): Partial<T> => {
  return !(input instanceof Object)
    ? input
    : Object.entries(input).reduce(
        (result, [k, v]) =>
          v === null || v === undefined
            ? result
            : ((result[k] =
                v instanceof Array ? v.map(pruned) : v instanceof Object ? pruned(v) : v),
              result),
        {} as any
      )
}

export const isObjectEmpty = (objectName: Object) => {
  return JSON.stringify(objectName) === '{}'
}

/**
 * Flattens a nested object into a single-level object using a dot delimiter for nested keys.
 *
 * @param obj - The nested object to flatten.
 * @param prefix - A prefix for the keys (used for recursion).
 * @returns The flattened object.
 */
export function flattenObject(obj: Record<string, any>, prefix = ''): Record<string, any> {
  const result: Record<string, any> = {}

  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(result, flattenObject(value, newKey))
    } else {
      result[newKey] = value
    }
  }

  return result
}

/**
 * Converts a flattened object with dot-delimited keys back into a nested object.
 *
 * @param flatObject - The flattened object to unflatten.
 * @returns The nested object.
 */
export function unflattenObject(flatObject: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {}

  for (const [key, value] of Object.entries(flatObject)) {
    const keys = key.split('.')
    let current = result

    keys.forEach((subKey, index) => {
      if (!current[subKey]) {
        current[subKey] = index === keys.length - 1 ? value : {}
      }
      current = current[subKey]
    })
  }

  return result
}
