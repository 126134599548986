import React, { useEffect, useRef } from 'react'
import SelectInput from '~src/common/SelectInput'
import { SelectedOptions, useTweakwiseApi } from '~src/hooks/useTweakwiseApi'
import { StarterConfig } from '~src/types/tweakwise/starter-config'

export const Tweakwise = () => {
  const { fetchData, loading, selected, setSelected, configurator } = useTweakwiseApi()
  const api = useRef<any>(null)

  useEffect(() => {
    // 1. Initialize Tweakwise with a small timeout to ensure the script is loaded.
    setTimeout(() => {
      initTweakwise(toConfig(selected))
    }, 400)

    // 2. Fetch initial data from Tweakwise API
    fetchData().catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    initTweakwise(toConfig(selected))
  }, [selected])

  /**
   * Manually initialize Tweakwise
   */
  function initTweakwise(config: any) {
    let listerPage = null

    if (api?.current) {
      api.current.destroy()
    }

    // hackje voor demo, bij een nette implementatie zou deze zou altijd beschikbaar moeten zijn
    if (!window.tweakwiseListerPage) {
      return
    }

    listerPage = window.tweakwiseListerPage(config)

    api.current = listerPage
    listerPage.setOpen(true)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          {configurator.map((item, i) => (
            <div key={i} className="mb-4">
              <h4>{item.facet?.facetsettings.title}</h4>
              <SelectInput
                disabled={!item.enabled}
                options={item.attributes.map((attr: any) => ({
                  title: attr.title,
                  value: attr.value,
                }))}
                getLabel={(o: any) => o.title}
                getValue={(o: any) => o.value}
                onChange={(m) => setSelected({ ...selected, [item.facet?.facetsettings.title]: m })}
                // @ts-ignore
                value={selected[item.facet?.facetsettings.title]}
                size="medium"
              />
            </div>
          ))}
        </div>
        <div className="col-lg-9">
          <div id="tweakwise-products-output">Loading...</div>
        </div>
      </div>
    </div>
  )
}

const toConfig = (selected: SelectedOptions): StarterConfig => {
  let selection = {}

  if (selected.type) {
    selection = {
      tn_fk_type: selected.type,
    }
  }
  if (selected.model) {
    selection = {
      tn_fk_model: selected.model,
    }
  }
  if (selected.year) {
    selection = {
      tn_fk_year: selected.year,
    }
  }

  return {
    output: '#tweakwise-products-output',
    cid: '1',
    query: null,
    filters: {
      position: 'top',
    },
    selection,
  }
}
