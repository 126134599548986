import * as Sentry from '@sentry/browser'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import PortalComponents from '~src/PortalComponents'
import ProductCarousel from '~src/apps/Commerce/ProductCarousel'
import ComparisonBar from '~src/apps/Comparison/ComparisonBar'
import ComparisonCheckbox from '~src/apps/Comparison/ComparisonCheckbox'
import SpecificationsModal from '~src/apps/Comparison/SpecificationsModal'
import Configurator from '~src/apps/Configurator/Configurator'
import ConfiguratorModalButton from '~src/apps/Configurator/ConfiguratorModalButton'
import { IApp } from '~src/apps/DealerMap/types'
import MotorInsurance from '~src/apps/MotorInsurance/MotorInsurance'
import Occasion from '~src/apps/Occasion/Occasion'
import OccasionOptions from '~src/apps/Occasion/OccasionOptions'
import SummaryPrices from '~src/apps/Occasion/SummaryPrices'
import WarrantyLabel from '~src/apps/Occasion/WarrantyLabel'
import { ShortFunnel } from '~src/apps/ShortFunnel/ShortFunnel'
import TradeInTool from '~src/apps/TradeIn/TradeInTool'
import { CarTradeInTool } from '~src/apps/CarTradeIn/CarTradeInTool'
import { LatestOccasions } from '~src/apps/LatestOccasions/LatestOccasions'
import { FinanceRequest } from '~src/apps/FinanceRequest/FinanceRequest'
import { FullColorLineUp } from '~src/apps/FullColorLineup/FullColorLineup'
import { Tweakwise } from '~src/apps/Tweakwise/Tweakwise'
import '~src/apps/gallery'
import ShareButtons from '~src/common/ShareButtons'
import { makeStore } from '~src/store'
import DealerMap from './apps/DealerMap/components/App'
import './dom_components/accessory-overview-form'
import './dom_components/add-to-cart-form'
import './dom_components/address-autofill'
import ArticleFilter from './dom_components/article-filter'
import './dom_components/carousel'
import './dom_components/collapse'
import DatePickers from './dom_components/date-pickers'
import DisclaimerForm from './dom_components/disclaimer-form'
import EditionsCarousel from './dom_components/editions-carousel'
import Forms from './dom_components/forms'
import './dom_components/iframe'
import './dom_components/overlay-page'
import './dom_components/modals'
import './dom_components/motorcycle-colors'
import NewsletterForm from './dom_components/newsletter-form'
import './dom_components/occasion-summary-block'
import './dom_components/prefill-forms'
import './dom_components/primary-nav'
import './dom_components/product-overview'
import './dom_components/product-teaser-carousel'
import './dom_components/service-tool'
import './dom_components/shopping-cart-form'
import './dom_components/specifications'
import './dom_components/testdrive-form'
import './dom_components/tooltip'
import './dom_components/variant-selector'

// Sentry
if (SENTRY_DSN_FRONTEND) {
  try {
    Sentry.init({
      dsn: SENTRY_DSN_FRONTEND,
      environment: SENTRY_ENVIRONMENT,
      denyUrls: [/assets\.exatom\.io/i, /clarity\.ms\/collect/i, /cdn\.cookielaw\.org/i],
    })
  } catch (err) {
    if (__DEV__) {
      console.error(err)
    }
  }
}

// Basic interaction like navigation
document.addEventListener('DOMContentLoaded', function () {
  DatePickers()
  ArticleFilter()
  Forms()
  DisclaimerForm()
  NewsletterForm()
  EditionsCarousel()
})

// Dealer Map check
function DealerMapCheck(props: IApp) {
  if (props.dealerApiUrl) {
    return <DealerMap {...props} />
  } else {
    console.error('Dealer map can not load, not all data is set.')
    return <div>There is a problem loading the dealer map.</div>
  }
}

// Create a store
const store = makeStore()
if (process.env.NODE_ENV === 'development') {
  ;(window as any).store = store
}

// Register container components
const components = {
  CarTradeInTool: CarTradeInTool,
  ComparisonBar: ComparisonBar,
  ComparisonCheckbox: ComparisonCheckbox,
  Configurator: Configurator,
  ConfiguratorModalButton: ConfiguratorModalButton,
  DealerMap: DealerMapCheck,
  EditionCarouselSpecifications: SpecificationsModal,
  FinanceRequest: FinanceRequest,
  FullColorLineUp: FullColorLineUp,
  LatestOccasions: LatestOccasions,
  MotorInsurance: MotorInsurance,
  Occasion: Occasion,
  OccasionOptions: OccasionOptions,
  OccasionSummaryPrices: SummaryPrices,
  OccasionWarrantyLabel: WarrantyLabel,
  ProductCarousel: ProductCarousel,
  ShareButtons: ShareButtons,
  ShortFunnel: ShortFunnel,
  TradeInTool: TradeInTool,
  Tweakwise: Tweakwise,
}

async function main() {
  // Render Portals
  const container = document.createElement('div')
  document.body.append(container)

  createRoot(container).render(
    <Provider store={store}>
      <PortalComponents components={components} />
    </Provider>
  )
}

main()
