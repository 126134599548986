import clsx from 'clsx'
import React, { type ChangeEvent, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { IEdition } from '~src/api/types/configurator'
import Accordion from '~src/common/Accordion'
import Tooltip from '~src/common/Tooltip'
import { groupByAsEntries } from '~src/utils/collection'
import { sortByTextAsc } from '~src/utils/sortByTextAsc'
import { applyMarkdownLink } from '~src/utils/text'
import IconCrossCircle from '~svg/cross-circle-v2.svg'
import IconSearch from '~svg/search.svg'
import CheckIcon from '~svg/checkmark.svg'
import DashIcon from '~svg/dash.svg'
import { useDebounce } from 'react-use'

const SpecificationsTable = ({
  editions,
  multipleColumns = true,
}: {
  editions: IEdition[]
  multipleColumns?: boolean
}) => {
  const [featuresSearchTerm, setFeaturesSearchTerm] = useState('')
  const [openFeaturesCategories, setOpenFeaturesCategories] = useState<string[]>([])
  const [specificationsSearchTerm, setSpecificationsSearchTerm] = useState('')
  const [openSpecificationsCategories, setOpenSpecificationsCategories] = useState<string[]>([])

  const hasTabs = editions.some((edition) => edition.features.length)

  return (
    <div className="specifications-table">
      {hasTabs ? (
        <Tabs>
          <TabList>
            <div className={clsx('container', !multipleColumns && 'text-center')}>
              <Tab className="react-tabs__tab react-tabs__tab--light">Uitrusting</Tab>
              <Tab className="react-tabs__tab react-tabs__tab--light">Specificaties</Tab>
            </div>
          </TabList>
          <TabPanel>
            <FeaturesContent
              editions={editions}
              multipleColumns={multipleColumns}
              searchTerm={featuresSearchTerm}
              setSearchTerm={setFeaturesSearchTerm}
              openCategories={openFeaturesCategories}
              setOpenCategories={setOpenFeaturesCategories}
            />
          </TabPanel>
          <TabPanel>
            <SpecificationsContent
              editions={editions}
              multipleColumns={multipleColumns}
              searchTerm={specificationsSearchTerm}
              setSearchTerm={setSpecificationsSearchTerm}
              openCategories={openSpecificationsCategories}
              setOpenCategories={setOpenSpecificationsCategories}
            />
          </TabPanel>
        </Tabs>
      ) : (
        <div className="react-tabs__tab-panel react-tabs__tab-panel--selected">
          <SpecificationsContent
            editions={editions}
            multipleColumns={multipleColumns}
            searchTerm={specificationsSearchTerm}
            setSearchTerm={setSpecificationsSearchTerm}
            openCategories={openSpecificationsCategories}
            setOpenCategories={setOpenSpecificationsCategories}
          />
        </div>
      )}
    </div>
  )
}

const FeaturesContent = ({
  editions,
  multipleColumns,
  searchTerm,
  setSearchTerm,
  openCategories,
  setOpenCategories,
}: {
  editions: IEdition[]
  multipleColumns?: boolean
  searchTerm: string
  setSearchTerm: (value: string) => void
  openCategories: string[]
  setOpenCategories: (value: string[]) => void
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value.toLowerCase())
  const handleReset = () => setOpenCategories([])

  const featuresByCategories = groupByAsEntries(
    editions.flatMap((edition) =>
      edition.features
        .filter((feature) => bySearchTerm(searchTerm, [feature.name]))
        .map((feature) => ({ edition, feature }))
    ),
    (e) => e.feature.category ?? 'overig'
  )

  const toggleAccordion = (category: string) => {
    if (openCategories.includes(category)) {
      setOpenCategories(openCategories.filter((c) => c !== category))
    } else {
      setOpenCategories([...openCategories, category])
    }
  }

  useDebounce(
    () => {
      if (searchTerm !== '') {
        setOpenCategories(
          featuresByCategories.filter(([_, items]) => items.length).map(([category]) => category)
        )
      } else {
        handleReset()
      }
    },
    200,
    [searchTerm]
  )

  return (
    <div className="container">
      <div className="search-box__input mb-3">
        <input
          type="text"
          className="form-control input-text"
          value={searchTerm}
          onChange={onChange}
          placeholder="Zoek op uitrusting..."
        />
        <button type="submit" className="search-box__btn-submit">
          <IconSearch />
        </button>
        <button
          type="button"
          className={clsx('search-box__btn-clear', searchTerm && 'active')}
          onClick={() => {
            setSearchTerm('')
            handleReset()
          }}
        >
          <IconCrossCircle />
        </button>
      </div>
      {featuresByCategories.map(([category, byCategory], i) => (
        <Accordion
          key={category}
          title={category}
          defaultOpen={i === 0}
          isOpen={searchTerm ? openCategories.includes(category) : undefined}
          onChange={toggleAccordion}
        >
          <div className="compare-table mb-4">
            {groupByAsEntries(byCategory, (f) => f.feature.name)
              .sort((a, b) => {
                return sortByTextAsc(a[0], b[0])
              })
              .map(([name, byFeatureName]) => (
                <TableRow
                  key={name}
                  tooltip={byFeatureName?.[0].feature.tooltip}
                  header={name}
                  editions={editions}
                  getCellValue={(edition) =>
                    byFeatureName.some((v) => v.edition === edition) ? (
                      byFeatureName[0].feature.standard ? (
                        <CheckIcon width={24} height={24} />
                      ) : (
                        <p>Optioneel</p>
                      )
                    ) : (
                      <DashIcon width={12} height={12} />
                    )
                  }
                  multipleColumns={multipleColumns}
                />
              ))}
          </div>
        </Accordion>
      ))}
    </div>
  )
}

const SpecificationsContent = ({
  editions,
  multipleColumns,
  searchTerm,
  setSearchTerm,
  openCategories,
  setOpenCategories,
}: {
  editions: IEdition[]
  multipleColumns?: boolean
  searchTerm: string
  setSearchTerm: (value: string) => void
  openCategories: string[]
  setOpenCategories: (value: string[]) => void
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value.toLowerCase())
  const handleReset = () => setOpenCategories([])

  const specificationsByCategories = groupByAsEntries(
    editions.flatMap((edition) =>
      edition.specifications
        .filter((specification) =>
          bySearchTerm(searchTerm, [specification.name, specification.value])
        )
        .map((specification) => ({ edition, specification }))
    ),
    (e) => e.specification.category ?? 'overig'
  )

  const toggleAccordion = (category: string) => {
    if (openCategories.includes(category)) {
      setOpenCategories(openCategories.filter((c) => c !== category))
    } else {
      setOpenCategories([...openCategories, category])
    }
  }

  useDebounce(
    () => {
      if (searchTerm !== '') {
        setOpenCategories(
          specificationsByCategories
            .filter(([_, items]) => items.length)
            .map(([category]) => category)
        )
      } else {
        handleReset()
      }
    },
    200,
    [searchTerm]
  )

  return (
    <div className="container">
      <div className="search-box__input mb-3">
        <input
          type="text"
          className="form-control input-text"
          value={searchTerm}
          onChange={onChange}
          placeholder="Zoek op specificaties..."
        />
        <button type="submit" className="search-box__btn-submit">
          <IconSearch />
        </button>
        <button
          type="button"
          className={clsx('search-box__btn-clear', searchTerm && 'active')}
          onClick={() => {
            setSearchTerm('')
            handleReset()
          }}
        >
          <IconCrossCircle />
        </button>
      </div>
      {specificationsByCategories.map(([category, byCategory], i) => (
        <Accordion
          key={category}
          title={category}
          defaultOpen={i === 0}
          isOpen={searchTerm ? openCategories.includes(category) : undefined}
          onChange={toggleAccordion}
        >
          <div className="compare-table mb-4">
            {groupByAsEntries(byCategory, (s) => s.specification.name)
              .filter(([_name, bySpecificationName]) =>
                bySpecificationName.some((v) => v.specification.value)
              )
              .sort((a, b) => sortByTextAsc(a[0], b[0]))
              .map(([name, bySpecificationName]) => (
                <TableRow
                  key={name}
                  header={name}
                  editions={editions}
                  getCellValue={(edition) => {
                    const text = bySpecificationName.find((v) => v.edition === edition)
                      ?.specification.value

                    return <div dangerouslySetInnerHTML={{ __html: applyMarkdownLink(text) }}></div>
                  }}
                  multipleColumns={multipleColumns}
                />
              ))}
          </div>
        </Accordion>
      ))}
    </div>
  )
}

const TableRow = ({
  header: name,
  tooltip,
  editions,
  getCellValue: getValue,
  multipleColumns,
}: {
  header: string
  tooltip?: string
  editions: IEdition[]
  getCellValue: (edition: IEdition) => React.ReactNode
  multipleColumns: boolean
}) => (
  <div className="compare-table__row">
    <div
      className={clsx('col-12 compare-table__header', multipleColumns ? 'col-lg-3' : 'col-lg-6')}
    >
      <div>
        {name}

        {tooltip && (
          <span className="ml-2">
            <Tooltip text={tooltip} />
          </span>
        )}
      </div>
    </div>
    {editions.map((edition) => (
      <div
        key={edition.code}
        className={clsx(
          'compare-table__cell',
          multipleColumns ? 'col-6 col-lg-3' : 'col-12 col-lg-6'
        )}
      >
        {getValue(edition)}
      </div>
    ))}
  </div>
)

export default SpecificationsTable

function bySearchTerm(searchTerm: string, values: string[]) {
  return values.some((value) => value.toLowerCase().includes(searchTerm))
}
