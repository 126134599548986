import { createAction } from '@reduxjs/toolkit'
import {
  ITradeInCar,
  ITradeInCustomer,
  ITradeInQuote,
  ITradeInSuggestion,
  StatusName,
} from './types'

export const setTradeInModalStatus = createAction<StatusName>('trade-in/set-status')
export const setTradeInCar = createAction<ITradeInCar>('trade-in/set-old-car')
export const removeTradeInCar = createAction('trade-in/remove-old-car')
export const updateTradeInCar = createAction<Partial<ITradeInCar>>('trade-in/update-old-car')
export const updateTradeInVersion = createAction<Partial<ITradeInCar>>('trade-in/update-version')
export const setNewCar = createAction<ITradeInSuggestion>('trade-in/set-new-car')
export const setNewCarWithTradeInQuote = createAction<{
  newCar: ITradeInSuggestion
  tradeInQuote: ITradeInQuote
}>('trade-in/set-new-car-with-trade-in-quote')
export const removeNewCarWithTradeInQuote = createAction(
  'trade-in/remove-new-car-with-trade-in-quote'
)
export const setSuccessMessage = createAction<string>('trade-in/set-success-message')
export const setNewCustomer = createAction<ITradeInCustomer>('trade-in/set-new-customer')
