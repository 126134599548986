import queryString from 'query-string'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet } from '~src/api/client'
import { IFinancialProduct } from '~src/api/types/configurator'
import Stepper from '~src/apps/Configurator/components/Stepper'

import { useRootState } from '~src/hooks/store'
import { ProductGroup } from '~src/store/configuration/types'
import { buildInitialState, setInitialState } from '~src/store/initialization'
import { setInitialNavigationState } from '~src/store/navigation/actions'
import { StepName } from '~src/store/navigation/types'
import { deserializeNavigation } from '~src/store/url-serializers'
const ModelStep = lazy(() => import('~src/apps/Configurator/components/steps/ModelStep'))
const EditionStep = lazy(() => import('~src/apps/Configurator/components/steps/EditionStep'))
const ConfigureStep = lazy(() => import('~src/apps/Configurator/components/steps/ConfigureStep'))
const SummaryStep = lazy(() => import('~src/apps/Configurator/components/steps/SummaryStep'))
const FinancialStep = lazy(() => import('~src/apps/Configurator/components/steps/FinancialStep'))

export type Props = {
  displayHeader: boolean
  apiRootUrl?: string
  apiUrl: string
  carConfigurationApiUrl: string
  paymentPlans: IFinancialProduct[]
  licensePlateApiUrl: string
  dealersApiUrl: string
  contactFormApiUrl: string
  prcodeApi: string
  googleMapsId: string
  googleApiKey: string
  louwmanhubBrand: string
  productGroup: ProductGroup
  financeRequestEnabled: boolean
}

type Disclaimers = {
  disclaimers: {
    car: string
    motor: string
    marine: string
    occassion: string
  }
}

const Configurator = (props: Props): JSX.Element => {
  const {
    displayHeader = true,
    apiRootUrl,
    apiUrl,
    carConfigurationApiUrl,
    paymentPlans,
    licensePlateApiUrl,
    dealersApiUrl,
    contactFormApiUrl,
    prcodeApi,
    googleApiKey,
    googleMapsId,
    louwmanhubBrand,
    productGroup,
    financeRequestEnabled,
  } = props
  const dispatch = useDispatch()

  useEffect(() => {
    const queryParams = queryString.extract(window.location.search || '')
    const navigation = deserializeNavigation(queryParams)
    dispatch(setInitialNavigationState(navigation))

    buildInitialState({
      modelsApiUrl: apiUrl,
      apiBaseUrl: apiRootUrl,
      financialProducts: paymentPlans,
    }).then((state) => {
      dispatch(setInitialState(state))
    })
  }, [])

  return (
    <>
      {displayHeader && (
        <header className="configurator__header">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-5">
                <Title />
              </div>
              <div className="col-12 col-sm-7">
                <Stepper />
              </div>
            </div>
          </div>
        </header>
      )}
      <ConfiguratorMain
        licensePlateApiUrl={licensePlateApiUrl}
        dealersApiUrl={dealersApiUrl}
        contactFormApiUrl={contactFormApiUrl}
        carConfigurationApiUrl={carConfigurationApiUrl}
        prcodeApi={prcodeApi}
        productGroup={productGroup}
        apiRootUrl={apiRootUrl}
        financeRequestEnabled={financeRequestEnabled}
        googleMapsId={googleMapsId}
        googleApiKey={googleApiKey}
        louwmanhubBrand={louwmanhubBrand}
      />
    </>
  )
}

export default Configurator

const Title = () => {
  const {
    navigation: { currentStep, financialPlan },
  } = useRootState()
  const title = financialPlan ? 'Bereken je maandbedrag' : 'Suzuki samenstellen'

  const labels: { [step in StepName]: string } = {
    configure: 'Samenstellen',
    edition: 'Model en uitvoering',
    finance: 'Geldzaken',
    model: 'Model en uitvoering',
    summary: `Samenvatting`,
  }

  return (
    <>
      <h4 className="configurator__title h5">{title}</h4>
      <h3 className="configurator__subtitle h3 mb-0" data-testid="current-step">
        {labels[currentStep]}
      </h3>
    </>
  )
}

const ConfiguratorMain = ({
  carConfigurationApiUrl,
  apiRootUrl,
  dealersApiUrl,
  contactFormApiUrl,
  licensePlateApiUrl,
  prcodeApi,
  googleMapsId,
  googleApiKey,
  louwmanhubBrand,
  productGroup,
  financeRequestEnabled,
}: {
  carConfigurationApiUrl: string
  apiRootUrl?: string
  dealersApiUrl: string
  contactFormApiUrl: string
  licensePlateApiUrl: string
  prcodeApi: string
  googleMapsId: string
  googleApiKey: string
  louwmanhubBrand: string
  productGroup: ProductGroup
  financeRequestEnabled: boolean
}) => {
  const {
    navigation: { currentStep },
  } = useRootState()
  const [disclaimer, setDisclaimer] = useState<string | undefined>()

  useEffect(() => {
    apiGet<Disclaimers>(`${apiRootUrl}site_settings/?format=json`).then(
      (result) => {
        if (productGroup === 'marine') setDisclaimer(result.data.disclaimers?.marine)
        if (productGroup === 'motors') setDisclaimer(result.data.disclaimers?.motor)
        if (productGroup === 'car') setDisclaimer(result.data.disclaimers?.car)
      },
      (error) => {
        console.error('Something went wrong in retrieving the disclaimer text: ', error)
      }
    )
  }, [])

  return (
    <div className="container">
      {currentStep === 'model' ? (
        <Suspense fallback={<span></span>}>
          <ModelStep disclaimer={disclaimer} />
        </Suspense>
      ) : currentStep === 'edition' ? (
        <Suspense fallback={<span></span>}>
          <EditionStep disclaimer={disclaimer} />
        </Suspense>
      ) : currentStep === 'configure' ? (
        <Suspense fallback={<span></span>}>
          <ConfigureStep disclaimer={disclaimer} />
        </Suspense>
      ) : currentStep === 'finance' ? (
        <Suspense fallback={<span></span>}>
          <FinancialStep
            dealersApiUrl={dealersApiUrl}
            contactFormApiUrl={contactFormApiUrl}
            apiRootUrl={apiRootUrl}
            licensePlateApiUrl={licensePlateApiUrl}
            prcodeApi={prcodeApi}
            disclaimer={disclaimer}
            productGroup={productGroup}
          />
        </Suspense>
      ) : currentStep === 'summary' ? (
        <Suspense fallback={<span></span>}>
          <SummaryStep
            carConfigurationApiUrl={carConfigurationApiUrl}
            dealersApiUrl={dealersApiUrl}
            apiRootUrl={apiRootUrl}
            prcodeApi={prcodeApi}
            googleApiKey={googleApiKey}
            googleMapsId={googleMapsId}
            louwmanhubBrand={louwmanhubBrand}
            productGroup={productGroup}
            disclaimer={disclaimer}
            financeRequestEnabled={financeRequestEnabled}
            licensePlateApiUrl={licensePlateApiUrl}
            contactFormApiUrl={contactFormApiUrl}
          />
        </Suspense>
      ) : null}
    </div>
  )
}
